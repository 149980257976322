import React from 'react'
import { graphql } from 'gatsby'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import Gallery from 'react-grid-gallery'

import SEO from '../components/seo'

const GalleryContainer = styled.div`
  flex: 0 1 90%;
  min-height: 1px; /* taken from react-grid-gallery demo */
  width: 90%;
  overflow: auto;
  padding: 30px 0;
`

const SubgalleryTemplate = ({
  data: {
    allPrismicSubgallery: {
      edges: [
        {
          node: {
            data: { name, items },
          },
        },
      ],
    },
  },
  intl,
}) => (
  <>
    <SEO title={`${name} - ${intl.formatMessage({ id: 'gallery' })}`} />
    <GalleryContainer>
      <Gallery
        images={items.map(({ image }) => ({
          src: image.url,
          thumbnail: image.url,
          thumbnailWidth: undefined, // TODO: Fix the errors or styling issues if this is provided
          thumbnailHeight: undefined,
          alt: image.alt,
          // caption: item.caption  // TODO: Ask about caption, description, date? etc.
        }))}
        backdropClosesModal
        enableImageSelection={false}
      />
    </GalleryContainer>
  </>
)

export default injectIntl(SubgalleryTemplate)

export const query = graphql`
  query($id: String!) {
    allPrismicSubgallery(filter: { id: { eq: $id } }) {
      edges {
        node {
          data {
            name
            items {
              image {
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`
